import React from "react";
import { useDropzone } from "react-dropzone";
// import "../../Assets/CSS/index.css";
function Basic(props) {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <section className="bx-dgndp">
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />

        <p></p>
        <aside style={{zIndex: 3}}>
        <h5>Drop your logo here or <p style={{color:"black"}}>Brows</p></h5>
        <ul className="upld_file">{files}</ul>
      </aside>
      </div>

      
    </section>
  );
}

<Basic />;
export default Basic;
