import React from "react";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import Dragndrop from "./Dragndrop";
import CheckIcon from "@material-ui/icons/Check";

function Home() {
  //const { isLoggingOut, logoutError } = this.props;
  const [colorName, setColorName] = React.useState("");
  const colorPick = [
    { color: "#b3ffab", color1: "#12fff7" },
    { color: "#ec77ab", color1: "#7873f5" },
    { color: "#007adf", color1: "#e14fad" },
    { color: "#b224ef", color1: "#7579ff" },
    { color: "#50cc7f", color1: "#f5d100" },
    { color: "#243949", color1: "#517fa4" },
  ];
  return (
    <div className="wksp_textfield">
      <div className="log_inleft">
        <div style={{ display: "flex" }}>
          <img
            src="https://idreamleaguesoccerkits.com/wp-content/uploads/2017/12/barcelona-logo.png"
            style={{ width: 50, height: 40, paddingLeft: 10, marginTop: 10 }}
            alt="side logo"
          />
          <p>MyToast</p>
        </div>
      </div>
      <div className="wksp_uprbx">
        <div>
          <h2
            style={{
              fontFamily: "Roboto",
              fontWeight: "400",
              color: "#325799",
            }}
          >
            Activate your web-portal
          </h2>
          <h3
            style={{
              fontFamily: "Roboto",
              fontWeight: "400",
              color: "#637493",
            }}
          >
            Enter Your workspace Name
          </h3>
          <div className="wksp_txtarea">
            <input
              style={{ border: 0, width: 100, outline: 0 }}
              type="text"
              placeholder="workspace-name"
            />
            {/* <form  className="wksp_txtarea">
      
      <div className="mytoastInput"><TextField id="outlined-basic" label="workspace-name" variant="outlined"  className="wksp_txtarea1"/></div>
      <span style={{fontWeight:"bold"}}>.mytoast.co</span>
    </form> */}
            <span style={{ fontWeight: "bold" }}>.mytoast.co</span>
          </div>{" "}
          <br />
          <div>
            <Dragndrop />
          </div>
          <h4 style={{ fontFamily: "Roboto", fontWeight: "400" }}>
            Select Theme Colour
          </h4>
          {colorPick.map((item, i) => (
            <Button
              key={i}
              style={{
                background: `linear-gradient(-225deg, ${item.color} 0%, ${item.color1} 56%, #FFBAC3 100%)`,
                width: "30px",
                height: "30px",
                margin: "5px",
                border: "none",
                minWidth: "0px",
                borderRadius: "50%",
              }}
              startIcon={
                item.color === colorName ? (
                  <div style={{ marginLeft: 10, marginTop: 10 }}>
                    <CheckIcon />
                  </div>
                ) : null
              }
              onClick={() => setColorName(item.color)}
            ></Button>
          ))}
          <Link to="/">
            <br />
            <button
              className="btnwksp"
              style={{ fontFamily: "Roboto", fontWeight: "400" }}
            >
              Activate Workspace
            </button>
            <br />
          </Link>
        </div>
        <div className="term_policy">
          <Button style={{ textTransform: "capitalize", color: "#325799" }}>
            Terms of services
          </Button>
          <span>|</span>
          <Button style={{ textTransform: "capitalize", color: "#325799" }}>
            Privacy Policy
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Home;
