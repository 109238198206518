import React from "react";
import { Button } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

export default function Dashboard() {
  const colorPick = [
    { color: "#b3ffab" },
    { color: "#ec77ab" },
    { color: "white" },
    { color: "white" },
    { color: "white" },
    { color: "white" },
  ];
  return (
    <div className="verify_email">
      <div className="log_inleft">
        <div style={{ display:"flex"}} >
         <img
                  src="https://idreamleaguesoccerkits.com/wp-content/uploads/2017/12/barcelona-logo.png"
                  style={{ width: 50, height: 40,paddingLeft: 10, marginTop: 10}} alt="side logo"
                />
        <p>MyToast</p>
        </div>
        </div>
      <div className="verify_emailbox">
        <div className="log_inright">
          <div className="verify_emailbox_main">
            <h1
              style={{
                fontFamily: "Roboto",
                fontWeight: "400",
                color: "#325799",
              }}
            >
              Verify your email
            </h1>
            <p>Check your email for an OTP code</p>
            {colorPick.map((item, i) => (
              <input key={i} />
            ))}
            <div>
              We've sent a 6-character code to <br />
              <p>
                {"<"}
                <span style={{ color: "#325799" }}>your.mail@work.com</span>
                {">"}
              </p>
              The code expires shortly, so please enter it soon.
            </div>
            <div style={{ display: "inline" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Button style={{ color:"white",
                  size:"small",textTransform: "capitalize"}}
                 
                  startIcon={<ArrowBackIcon />}
                >
                  <p style={{ color: "#325799" }}>Back</p>
                </Button>
                <Button style={{ color:"white",
                  size:"small",textTransform: "capitalize"}}
                 
                  endIcon={<ArrowForwardIcon />}
                >
                  <p style={{ color: "#325799" }}>Resend to Email</p>
                </Button>
              </div>
            </div>
           
          </div>
           <div className="term_policy">
            <Button style={{ textTransform: "capitalize" }}>
              <p>Terms of services </p>
            </Button>
            <span>|</span>
            <Button style={{ textTransform: "capitalize" }}>
              <p>Privacy Policy</p>
            </Button>
          </div>
        </div>
        
      </div>
    </div>
  );
}
