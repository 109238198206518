import React from "react";

import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";

export default function Signin() {
  return (
    <>
      <div className="log_in">
        <div className="log_inleft">
          <div style={{ display: "flex" }}>
            {" "}
            <img
              src="https://idreamleaguesoccerkits.com/wp-content/uploads/2017/12/barcelona-logo.png"
              style={{ width: 50, height: 40, paddingLeft: 10, marginTop: 10}}
              alt="side logo"
            />
            <p>MyToast</p>
          </div>
        </div>
        <div className="log_in_box">
          <div>
            <h1>Signin to MyToast</h1>
            <p>
              Lorem Ipsum, sometimes referred to as 'lipsum', is the <br />{" "}
              placeholder text used in design when creating content
            </p>

            <div className="login_size">
              <img
                src="https://image.flaticon.com/icons/png/512/2991/2991148.png"
                
                alt="login logo"
              />
              <Button
                className="login_sizetxt"
                style={{ textTransform: "capitalize" }}
              >
                Signin with Google
              </Button>
            </div>

            <div className="login_lnkdln">
              <img
                src="https://png.pngtree.com/element_our/md/20180626/md_5b321c9756fc6.jpg"
                
                alt="login logo"
              />
              <Button
                className="login_sizetxt"
                style={{
                  border: "none",
                  backgroundColor: "#0E87CB",
                  color: "white",
                  textTransform: "capitalize",
                }}
              >
                Signin with Linkdln
              </Button>
            </div>
            <div id="or">OR</div>
            <div>
              <input
                id="inputID"
                className="wksp_txtarealogin"
                placeholder="email@workspac-email.com"
              />
            </div>

            <div>
              <button className="btnwksplogin">
                <Link to="/signin-magic" className="login_link">
                  Signin with Email
                </Link>
              </button>
            </div>
            <div className="para_bottom">
              <div>
                <img
                  src="https://image.flaticon.com/icons/png/512/685/685173.png"
                  alt="login logo"
                  className="para_bottom_img"
                />
              </div>
              <p>
                We'll email you a magic code for verification.
                <br />
              </p>
            </div>
            <Button>
              <Link to="/Signup" style={{ textDecoration: "none" }}>
                <span className="para_bottom_link">Create an account</span>
              </Link>
            </Button>
          </div>
          <div className="term_policy">
            <Button style={{ textTransform: "capitalize" }}>
              Terms of services
            </Button>
            <span>| </span>
            <Button style={{ textTransform: "capitalize" }}>
              Privacy Policy
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
