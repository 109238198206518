import React from "react";
import { Route, Switch } from "react-router-dom";
import Workspace from "./Pages/Workspace/Workspace";
import Signin_magic from "./components/Signin_magic";
import Verify_email from "./components/Verify_email";
import Welcome_back from "./components/Welcome_back";
import Signup from "./components/Signup";
import Login from "./components/Login";
function App() {
  return (

    
    <Switch>    
      <Route exact path="/" component={Login} />
      <Route exact path="/signup" component={Signup} />
      <Route exact path="/signin-magic" component={Signin_magic} />
      <Route exact path="/welcome_back" component={Welcome_back} />
      <Route exact path="/verify_email" component={Verify_email} />
      <Route exact path="/create-workspace" component={Workspace} />
    </Switch>
  
  );
}



export default App;